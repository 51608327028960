import { Grid, ThemeProvider, Typography } from "@material-ui/core";
import makeStyles from "@material-ui/styles/makeStyles";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import store from "store";
import theme from "../../assets/theme";
import CustomButtton from "../../components/CustomButtons/CustomButton";
import NetworkProgress from "../../mycomponents/NetworkProgress";
import {
  clearRank,
  clearResult,
  fetchRankAnalysis,
  fetchResultDetails,
} from "../../redux/actions/resultPageActions/actionCreators";
import BottomNav from "../BottomNav";
import CompareResult from "./CompareResult";
import ComparisonCard from "./ComparisonCards";
import MyAnswers from "./MyAnswers";
import NoResultMsg from "./NoResultMsg";
import ResultHeader from "./ResultHeader";
const useStyles = makeStyles(() => ({
  content: {
    gap: "20px",
    paddingTop: "20px",
    [theme.breakpoints.up("md")]: {
      padding: "20px 20%",
    },
  },
  noKey: {
    marginTop: "10%",
  },
  pdf: {
    marginTop: "8%",
  },
  itemContainer: {
    width: "100%",
  },
}));
const Index = (props) => {
  const { location, match } = props;
  const exam = store.get("examObject") || {};
  const query = new URLSearchParams(location.search);
  const [customQuestionsCount, setCustomQuestionCount] = useState(30);
  const user = store.get("user");
  const resultData = useSelector((state) => state.allResult);
  // console.log(resultData);
  const rankAnalysis = useSelector((state) => state.rankAnalysis);
  const dispatch = useDispatch();
  const [account, studentIds, _id, level, pdf, isAdmin] = [
    query.get("accountType"),
    query.get("studentIds"),
    query.get("_id"),
    query.get("level"),
    query.get("pdfLink"),
    query.get("isAdmin"),
  ];
  const userLevel = level || (user && user.level);
  const accountType = account || (user && user.accountType);
  const student_id = studentIds || (user && user.studentIds);
  const pdfLink = pdf || (exam && exam.pdfLink);
  // console.log(resultData, rankAnalysis);
  useEffect(() => {
    setCustomQuestionCount(resultData.dataResult.length);
  }, [resultData, rankAnalysis]);
  useEffect(() => {
    const body = {
      student_id:
        accountType && accountType === "parent"
          ? student_id
          : _id || store.get("user")._id,
      test_name: match.params.test_name,
      code: query.get("code"),
    };
    try {
      dispatch(fetchResultDetails(body));
      dispatch(fetchRankAnalysis(body));
    } catch (error) {
      console.log(error);
    }
    return () => {
      dispatch(clearResult());
      dispatch(clearRank());
    };
  }, []);
  const classes = useStyles();

  if (rankAnalysis.error) {
    return <NoResultMsg header />;
  }
  if (userLevel == "19189210")
    return (
      <NoResultMsg
        header
        text="Your test got submitted successfully, Results will be announced after two day."
      />
    );

  if (
    ["211210", "2119710"].includes(`${userLevel}`) &&
    accountType !== "parent"
  )
    return <NoResultMsg header />;

  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        {!rankAnalysis.isLoading && resultData.keyAvailable && (
          <div style={{ padding: "0 20px" }}>
            {/* header start */}
            {isAdmin ? null : (
              <ResultHeader testDisplayName={query.get("test")} />
            )}
            {/* header end */}
            {/* content start */}
            {navigator.userAgent.includes("Android") &&
              window.Android &&
              pdfLink && (
                <Grid container className={classes.pdf}>
                  <Grid item>
                    &emsp;
                    <CustomButtton
                      label="View PDF"
                      handleGetClick={() => window.Android.showPDF(pdfLink)}
                    />
                  </Grid>
                </Grid>
              )}
            <Grid container className={classes.content}>
              {/* rank ,grade and accuracy cards */}
              <Grid item className={classes.itemContainer}>
                <ComparisonCard
                  customQuestionsCount={customQuestionsCount}
                  paper={query.get("paper")}
                  test_type={query.get("type")}
                />
              </Grid>
              {/* if showResult exist then only show answer and statistics section  */}
              {rankAnalysis.data.showResult === false ? null : (
                <React.Fragment>
                  {/* my answers and my statistics section*/}
                  <Grid item className={classes.itemContainer}>
                    <MyAnswers />
                  </Grid>
                  {/* comparison of results with other students */}
                  <Grid item className={classes.itemContainer}>
                    <CompareResult
                      test_name={match.params.test_name}
                      id={_id || store.get("user")._id}
                    />
                  </Grid>
                </React.Fragment>
              )}
            </Grid>
            {/* content ends */}
          </div>
        )}
        {!resultData.isLoading && !resultData.keyAvailable && (
          <Grid container direction="row" justify="center" alignItems="center">
            <Typography component="h1" variant="h3" className={classes.noKey}>
              {userLevel == "2119611"
                ? "Thank you for choosing B-SAT. Results will be announced soon."
                : "Key is not uploaded to evaluate"}
            </Typography>
          </Grid>
        )}
        {resultData.isLoading && <NetworkProgress> </NetworkProgress>}
      </ThemeProvider>
      <BottomNav />
    </React.Fragment>
  );
};

export default Index;
