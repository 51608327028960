import { Box, Hidden } from "@material-ui/core";
import Button from "@material-ui/core/Button";
// Material components
import Grid from "@material-ui/core/Grid";
// Material helpers
import { ThemeProvider } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import quotes from "assets/img/quote.png";
// Externals
import PropTypes from "prop-types";
import React, { Fragment, useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import compose from "recompose/compose";
import store from "store";
import { getAppSettingsInfo } from "../../apis/appSettings";
// Component styles
import { Suspense } from "react";
import ClearCache, { useClearCache } from "react-clear-cache";
import {
  changeFirstLogin,
  parentLoginApi,
  userLogin,
} from "../../apis/UserService";
import theme from "../../assets/theme";
import { useDispatch, useSelector } from "react-redux";
import AlertComponent from "../../mycomponents/AlertComponent";
import NetworkProgress from "../../mycomponents/NetworkProgress";
import checkAfterLogin from "../../utils/checkAfterLogin";
import { CustomProgress } from "./components/CustomInput";
import InputBase1 from "./components/InputBase1";
const LazyLoadImages = React.lazy(() =>
  import("../../mycomponents/LazyLoadImages")
);
const useStyle = makeStyles(() => ({
  signInBg: {
    background: theme.palette.background.paper,
    minHeight: "100vh",
  },
  separator: {
    marginTop: "20px",
    width: "55px",
    height: "3px",
    borderRadius: "5px",
  },
  link: {
    color: theme.link.main,
    "&:hover": {
      color: theme.link.hover,
    },
  },
  planLink: {
    position: "fixed",
    top: 0,
    right: 0,
    margin: "5%",
    [theme.breakpoints.up("md")]: {
      marginRight: "11%",
      marginTop: "3%",
    },
  },
  sendMessage: {
    textTransform: "none",
    height: "56px",
    width: "220px",
    background: "#08C5A7",
    boxShadow: "inset 1px 1px 1px #04E8C3, inset -1px -1px 1px #07B096",
    borderRadius: "16px",
    // fontFamily: 'Montserrat',
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "100%",
    /* or 18px */
    textAlign: "center",
    color: "#FFFFFF",
    "&:hover": {
      background: "#0eab8f",
    },
  },
}));

// Service methods

const signIn = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, 1500);
  });
};

const SignIn = (props) => {
  const { isLatestVersion, emptyCacheStorage } = useClearCache();
  const { history } = props;
  const isMobile = useMediaQuery("(max-width:600px)");
  const userFromStore = store.get("USER_ID");
  const [quote, setQuote] = useState("");
  const [logo, setLogo] = useState("");
  const [mini, setMini] = useState("");
  const [values, setValues] = useState({
    userId: "",
    password: "",
  });
  const [touched, setTouched] = useState({
    userId: false,
    password: false,
  });
  const [miniDimensions, setMiniDimensions] = useState({});
  const [errors, setErrors] = useState({
    userId: null,
    password: null,
  });
  const [isValid, setIsValid] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(true);
  const { hostname, origin } = window.location;
  const [idPwdMatched, setIdPwdMatched] = useState(
    localStorage.getItem("matched") === "false"
  );
  const handleCloseAlertBox = () => {
    setConfirmOpen(false);
    emptyCacheStorage();
  };
  let storeInterval;
  useEffect(() => {
    store.set("matched", idPwdMatched);

    if (!store.get("domainConfig"))
      storeInterval = setInterval(() => {
        const domainConfig = store.get("domainConfig");
        console.log(store.get("domainConfig"));
        if (domainConfig && domainConfig.signin) {
          clearInterval(storeInterval);
          setMiniDimensions({ miniDimensions: domainConfig.signin.mini });
        }
      }, 1500);
    else {
      const domainConfig = store.get("domainConfig");
      if (domainConfig && domainConfig.signin) {
        clearInterval(storeInterval);
        setMiniDimensions({ miniDimensions: domainConfig.signin.mini });
      }
    }
    console.log(mini);
    const company = window.location.hostname.split(".")[0];
    const mainlogo_url =
      "https://onesaz-new.s3.ap-south-1.amazonaws.com/logos/" +
      company +
      ".png";
    const minilogo_url =
      "https://onesaz-new.s3.ap-south-1.amazonaws.com/logos/" +
      company +
      "_mini.png";
    setLogo(mainlogo_url);
    setMini(minilogo_url);
    if (window.location.host.includes("localhost")) {
      setLogo(
        "https://onesaz-new.s3.ap-south-1.amazonaws.com/logos/onesaz.png"
      );
      setMini(
        "https://onesaz-new.s3.ap-south-1.amazonaws.com/logos/onesaz_mini.png"
      );
      setQuote(quotes);
    }
    // forceUpdate();

    return () => {
      clearInterval(storeInterval);
    };
  }, [idPwdMatched]);

  const handleBack = () => {
    history.goBack();
  };

  const handleFieldChange = (field, value) => {
    setSubmitError(null);
    setTouched({ ...touched, [field]: true });
    // Prevent special characters for the userId field
    if (field === "userId") {
      const regex = /^[a-zA-Z0-9\s]*$/;
      if (!regex.test(value)) {
        return;
      }
    }
    setValues({ ...values, [field]: value });
    if (errors.userId || errors.password) {
      setErrors({ ...errors, userId: false, password: false });
      setIsValid(true);
    }
  };
  const handleKeyPress = (event) => {
    // Allow only alphanumeric characters and spaces
    const regex = /^[a-zA-Z0-9\s]*$/;
    const key = event.key;
    if (!regex.test(key)) {
      event.preventDefault();
    }
  };
  const handleTouchStart = (event) => {
    event.preventDefault();
  };

  const handleSignIn = async () => {
    try {
      if (userFromStore && userFromStore !== values.userId) {
        store.clearAll();
      }
      setIsLoading(true);

      // if user id is phone no. for private login consider it as parent account
      let res =
        hostname !== "onesaz.com" &&
        hostname !== "www.onesaz.com" &&
        /^[0-9]{10}$/.test(values.userId)
          ? await parentLoginApi({
              phoneNumber: values.userId,
              password: values.password,
            })
          : await userLogin({
              phoneNumber: values.userId,
              password: values.password,
            });
      localStorage.setItem("isAuthenticated", true);
      res && res.data
        ? store.set("user", {
            ...res.data,
            students: res.data.studentIds || null,
          })
        : store.set("");
      if (res.response && res.response.status === 400) {
        setErrors({
          ...errors,
          userId: null,
          password: "Email or password field cannot be empty",
        });
        setIsValid(false);
        setIdPwdMatched(false);
      }
      if (res.response && res.response.status === 401) {
        const errmsg = res.response.data
          ? res.response.data.message
          : "Incorrect username/password";
        setErrors({
          ...errors,
          userId: null,
          password: res.response.data.message,
        });
        setIdPwdMatched(false);
      }
      if (res && res.data) {
        const appSettings = await getAppSettingsInfo({
          instituteId: res.data.institute,
        });
        if (appSettings && appSettings.result);
        {
          store.set("appSettings", appSettings.result);
        }
      }
      // check if userId matches with password
      if (
        values.userId.toLowerCase() === values.password.toLowerCase() &&
        res.status &&
        res.status === 200 &&
        !res.data.accountType
      ) {
        setIdPwdMatched(true);
        store.set("matched", true);
        history.push("/id-and-password-matched");
      } else if (res.data.accountType && res.data.accountType === "parent") {
        const { firstLogin, _id } = res.data;
        const targetRoute = "/parent-profile";

        if (firstLogin) {
          changeFirstLogin({ id: _id });
        }
        // if (firstLogin && values.password.length !== 6) {
        //   store.set("firstLogin", firstLogin);
        // }
        history.push(targetRoute);
      } else {
        history.push("/home-page");
      }

      if (navigator.userAgent.includes("Android") && window.Android) {
        window.Android.postFCMToken(
          res && res.data && res.data._id ? `${res.data._id}` : ""
        );
      }
    } catch (error) {
      setIsLoading(false);
      setSubmitError(error);
    } finally {
      setIsLoading(false);
    }
  };
  const doRedirect = async () => {
    let loggedIn = store.get("user");
    if (loggedIn) {
      const domainConfig = store.get("domainConfig");
      if (domainConfig && domainConfig["afterLogin"]) {
        checkAfterLogin(domainConfig, props);
      } else {
        history.push("/home-page");
      }
    }
  };

  doRedirect();
  const classes = useStyle();
  return (
    <ThemeProvider theme={theme}>
      <ClearCache>
        {({ isLatestVersion, emptyCacheStorage }) => (
          <Fragment>
            {!isLatestVersion ? (
              <AlertComponent
                open={confirmOpen}
                alertType={"update"}
                closeAlert={handleCloseAlertBox}
              >
                New Update Available
                <br />
                <Button onClick={() => emptyCacheStorage()}>Update</Button>
              </AlertComponent>
            ) : (
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                className={classes.signInBg}
              >
                <Grid item xs={12} lg={7}>
                  {/* {
                    window.location.host.includes("bhashyam.onesaz") ||
                      window.location.host.includes("localhost") ? (
                      <RegisterExamCard />
                    )
                      : (
                       
                      )
                  } */}
                  <Hidden mdDown>
                    <Suspense fallback={<NetworkProgress />}>
                      <LazyLoadImages
                        style={{ marginTop: "-5%" }}
                        src={logo}
                        alt="logo"
                      />
                    </Suspense>
                  </Hidden>
                </Grid>
                <Grid item xs={12} sm={10} lg={5} style={{ minHeight: "100%" }}>
                  <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                    style={{ minHeight: "100%", minWidth: "320px" }}
                  >
                    {/* <Grid item xs={12}>
                      {hostname.includes("agastya") && (
                        <Typography variant="subtitle2" color="primary">
                          Dear parents for <b>ONLINE TALENT HUNT EXAM</b>
                          &nbsp;,&nbsp;
                          <Typography variant="subtitle1" component="span">
                            <Link className={classes.link} to="/register">
                              Click here to&nbsp;
                              <Button
                                size="small"
                                color="primary"
                                variant="outlined"
                              >
                                Register
                              </Button>
                            </Link>
                          </Typography>
                        </Typography>
                      )}
                    </Grid> */}

                    {miniDimensions && (
                      <Grid item xs={12}>
                        <Suspense fallback={<NetworkProgress />}>
                          <LazyLoadImages
                            alt="logo"
                            src={mini}
                            width={
                              miniDimensions.width
                                ? miniDimensions.width
                                : "100px"
                            }
                            height={
                              miniDimensions.height
                                ? miniDimensions.height
                                : "100px"
                            }
                          />
                        </Suspense>

                        <br />
                        <br />
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Typography
                        component="div"
                        variant="h3"
                        color="textPrimary"
                      >
                        Sign In
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Box
                        bgcolor="primary.main"
                        className={classes.separator}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <br />
                      <InputBase1
                        error={errors.email ? true : false}
                        label="User ID"
                        name="userId"
                        onChange={(event) =>
                          handleFieldChange("userId", event.target.value)
                        }
                        onKeyPress={handleKeyPress}
                        onTouchStart={handleTouchStart}
                        placeholder="User Id"
                        type="text"
                        value={values.userId}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      {errors.userId && (
                        <Typography
                          variant="body2"
                          align="center"
                          color="error"
                        >
                          {errors.userId}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <br />
                      <InputBase1
                        error={errors.password ? true : false}
                        label="Password"
                        name="password"
                        placeholder="password"
                        onChange={(event) =>
                          handleFieldChange("password", event.target.value)
                        }
                        type="password"
                        value={values.password}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      {errors.password &&
                      errors.password.indexOf(".onesaz.com") >= 0 ? (
                        <Typography
                          variant="body2"
                          align="center"
                          color="error"
                        >
                          {" "}
                          Click on
                          <a
                            style={{ color: "#08C5A7" }}
                            onClick={(e) => {
                              window.location.replace(
                                `${"https://"}${errors.password}`
                              );
                              e.preventDefault();
                            }}
                          >
                            {" "}
                            {errors.password}
                          </a>{" "}
                          url to login{" "}
                        </Typography>
                      ) : (
                        <Typography
                          variant="body2"
                          align="center"
                          color="error"
                        >
                          {errors.password}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <br />
                      {isLoading ? (
                        <CustomProgress />
                      ) : (
                        <Button
                          disabled={!isValid}
                          onClick={handleSignIn}
                          size="large"
                          color="primary"
                          variant="contained"
                        >
                          Sign in Now
                        </Button>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant="subtitle1"
                        color="primary"
                        style={{ marginTop: "15px" }}
                      >
                        <Link to="/forgot" className={classes.link}>
                          Forgot password?{" "}
                        </Link>
                      </Typography>
                    </Grid>
                    {/* commenting cause...... register link in bsat */}
                    {origin === "https://www.onesaz.com" ||
                    origin === "https://onesaz.com" ||
                    hostname.includes("localhost") ||
                    hostname.includes("agastya") ||
                    hostname.includes("valleyoak") ||
                    hostname.includes("sriabhidakp") ||
                    hostname.includes("viitjee") ? (
                      <Grid item xs={12}>
                        <Typography variant="subtitle2" color="textSecondary">
                          Don't have an account?{" "}
                          <Typography variant="subtitle1" component="span">
                            <Link className={classes.link} to="/register">
                              Sign up
                            </Link>
                          </Typography>
                        </Typography>
                      </Grid>
                    ) : null}
                    {hostname.includes("tirumala") && (
                      <Grid item xs={12}>
                        <Typography variant="subtitle2" color="textSecondary">
                          Need Technical help? contact us :
                        </Typography>
                        <Typography variant="subtitle1" color="textPrimary">
                          +91&nbsp;9553049631 &nbsp;/&nbsp;+91&nbsp;9390567283
                        </Typography>
                      </Grid>
                    )}
                    <br />
                    <br />
                    <br />
                  </Grid>
                </Grid>
                {origin === "https://onesaz.com" ||
                origin === "https://onesaz.com" ? (
                  <React.Fragment>
                    <Typography variant="subtitle2" color="textSecondary">
                      Follow us{" "}
                    </Typography>
                    <br />
                    <a href="https://www.instagram.com/onesazlearning/">
                      <img
                        alt="logo"
                        width={
                          miniDimensions.width ? miniDimensions.width : "50px"
                        }
                        height={
                          miniDimensions.height ? miniDimensions.height : "50px"
                        }
                        src={
                          "https://onesaz-new.s3.ap-south-1.amazonaws.com/logos/instagram_logo.png"
                        }
                      />
                    </a>
                    <a href="https://www.facebook.com/Onesaz-100157108772069">
                      <img
                        alt="logo"
                        width={
                          miniDimensions.width ? miniDimensions.width : "45px"
                        }
                        height={
                          miniDimensions.height ? miniDimensions.height : "45px"
                        }
                        src={
                          "https://onesaz-new.s3.ap-south-1.amazonaws.com/logos/facebook_logo.png"
                        }
                      />
                    </a>
                  </React.Fragment>
                ) : null}
              </Grid>
            )}
          </Fragment>
        )}
      </ClearCache>
    </ThemeProvider>
  );
};

SignIn.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default compose(withRouter)(SignIn);
